import { useEffect } from "react";
import socket from './../socket/socket'


const useSocket= (eventName, eventHandler)=>{

    //socket
  useEffect(() => {
    //on New connection
    socket.on(eventName, eventHandler);

    return () => {
     socket.off(eventName, eventHandler);
    }
  }, [eventHandler]);


  return socket;

}

export default useSocket;