import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getBottomMenu, getData, getLayout, getOttSettings, getPage } from "../../../Api/api";
import { utility } from "../../../utility/utility";
import { useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';
import { MainPage } from '../../MainPage';
import useSocket from "../../../socket/useSocket";
import { LOCAL_STORAGE_KEY, MODULE, SOCKET_ACTION, SOCKET_EVENTS } from "../../../constant";
import MainWrapper from "../../MainWrapper";

const Preview = () => {

    const [page, setPage] = useState([]);
    const [menu, setMenu] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ottSetting, setOttSetting] = useState({});
    const location = useLocation();
    const { ref, focusKey } = useFocusable();

    function getQueryVariable() {
        var query = window.location.href;
        let index = query.indexOf('=');
        if (index >= 0) {
            query = query.substring(index + 1);
            return query;
        } else {
            return '';
        }
    }

    const pageIdFromQuery = getQueryVariable()?.toString();


    useEffect(() => {
        loadData();
    }, [location?.state?.page])


    const getPageFromQuery = async () => {
        if (pageIdFromQuery) {
            let res = await getPage(pageIdFromQuery)
            if (res.success) {
                setPage(res.data);
            }
        }
        const ottSettingRes = await getOttSettings();
        if (ottSettingRes.success) {
            console.log(ottSettingRes);
            setOttSetting({ ...ottSettingRes.data, piPageSetting: ottSettingRes?.piPageSetting });
        }
    }

    // params
    const isMobile = location.pathname.replace('/', '') == 'mobile' ? true : false;

    //socket
    const socket = useSocket(SOCKET_EVENTS.onSocketData, (socketData) => {

        if (socketData.module != MODULE.PLATFORM) return;

        switch (socketData.action) {
            case SOCKET_ACTION.PLATFORM_THEME_REFRESH:
            case SOCKET_ACTION.PLATFORM_GRID_REFRESH:
            case SOCKET_ACTION.PLATFORM_RAIL_REFRESH:
                loadOttSettings();
                break;
            default:
                break;
        }
    });

    const loadData = async () => {
        setLoading(true);

        if (pageIdFromQuery && pageIdFromQuery?.length > 0) {
            await getPageFromQuery();
        } else if (location.state && location.state.page) {
            let pageRes = await getPage(location.state.page._id);
            if (pageRes.success) {
                setPage(pageRes.data);
                const ottSettingRes = await getOttSettings();
                if (ottSettingRes.success) {
                    console.log(ottSettingRes);
                    setOttSetting({ ...ottSettingRes.data, piPageSetting: ottSettingRes?.piPageSetting });
                }
            } else {
                setPage(location.state.page);
            }
        } else {
            await loadOttSettings();
        }
        setLoading(false);
    }

    const loadLayout = async (settings) => {

        let pageID = settings.homePage

        if (settings.showBottomMenu) {
            const bottomMenuRes = await getBottomMenu();
            const allPages = await getData('ott_pages');
            if (allPages.success) {
                console.log(allPages);
                utility.setValue(LOCAL_STORAGE_KEY.pages, allPages.data)
            }
            console.log(bottomMenuRes)
            setMenu(bottomMenuRes.data.Pages);
            if (bottomMenuRes.success) {
                if (bottomMenuRes.data.Pages && bottomMenuRes.data.Pages.length > 0) {
                    pageID = bottomMenuRes.data.Pages[0]._id
                }
            }
        }

        const response = await getPage(pageID);
        if (response.success) {
            setPage(response.data);
        }


    }

    const loadOttSettings = async () => {
        const ottSettingRes = await getOttSettings();
        if (ottSettingRes.success) {
            console.log(ottSettingRes);
            setOttSetting({ ...ottSettingRes.data, piPageSetting: ottSettingRes?.piPageSetting });
            await loadLayout(ottSettingRes.data);
        }
    }

    return loading ?
        <div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center", overflow: 'hidden' }}>
            <span style={{ position: "fixed", top: "7px", left: "50%", transform: "translate(-50%, 0)", backgroundColor: "#FFF9C9", padding: "3px 7px", fontWeight: "bold", fontSize: "13px", boxShadow: "0 3px 3px rgba(0,0,0,0.23)" }}>
                Loading ...
            </span>
            <i style={{ fontSize: "42px" }} className="fa fa-spin fa-spinner" />
        </div>
        : <FocusContext.Provider value={focusKey}>
            <MainWrapper focusKeyParam="CONTAINER"
                page={page} ottSetting={ottSetting} menu={menu} isMobile={isMobile} />
        </FocusContext.Provider>
}

export default Preview;