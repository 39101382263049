import moment from 'moment';
import React, { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

const EpgTimeline = ({
    slotDuration,
    blockWidth
}) => {

    let timelineTime = [];
    let totalMinutesInADay = 24 * 60;
    let tempStatTime = 0;

    while (tempStatTime < totalMinutesInADay) {
        timelineTime.push(moment().startOf('day').add(tempStatTime, 'minute').format('HH:mm'))
        tempStatTime += slotDuration;
    }

    return (
        <div style={{ position: "relative" }}>
            <div style={{ position: "relative", top: 0, left: 0, zIndex: 10, backgroundColor: "#202936", color: "#e4e4e4", width: "150px", height: "40px", lineHeight: "40px", textAlign: "center" }}>
                Channel/Time
            </div>
            <div id={"timeline"} style={{ overflow: "scroll", height: "40px", whiteSpace: "nowrap", paddingLeft: "150px", position: "absolute", top: 0, backgroundColor: "white", zIndex: 2, width: "100vw" }}>
                {/* 00:00 - 23:59 */}
                {timelineTime.map(t => {
                    return <div key={uuidv4()} style={{ display: "inline-block", width: blockWidth, textAlign: "left", height: "100%", lineHeight: "40px", border: "1px solid black", backgroundColor: "#202936", color: "#e4e4e4", paddingLeft: "5px" }}>{t}</div>
                })}
            </div>
        </div>
    );
}

export default EpgTimeline;
