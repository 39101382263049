const RAILTYPE = {
  Logo: 1,
  HeroBanner: 2,
  Rail: 3,
  TEXT: 4,
  VerticalRail: 5,
  SingleCard: 6,
  GridRailTwoColumn: 7,
  GridRailThreeColumn: 8,
  GridRailFourColumn: 9,
  FullPageGrid2: 10,
  FullPageGrid3: 11,
  FullPageGrid4: 12,
  TextRail: 13,
  CircleRail: 14,
  PotraitRail: 15,
  LandscapeRail: 16,
  SquareRail: 17,
  FastChannel: 18,
  Document: 19,
  TopCounter: 20,
  HeroBannerPotrait: 21,
}

const SOCKET_EVENTS = {
  GRID_REFRESH: "GRID_REFRESH",
  newConnection: "newConnection",
  onSocketData: "onSocketData",
  onSocketUsers: "onSocketUsers",
  closeConnection: "closeConnection",
  onSocketNotification: 'onSocketNotification',
  onProgressUpdate: "onProgressUpdate",
}

const MODULE = {
  ALL: 0,
  THEME: 198,
  PLATFORM: 200,
}

const SOCKET_ACTION = {
  UPDATE_ACTIVE_USER: "UPDATE_ACTIVE_USER",
  ADD_ACTIVE_USER: "ADD_ACTIVE_USER",
  REMOVE_ACTIVE_USER: "REMOVE_ACTIVE_USER",
  SHOW_TOAST_MESSAGE: "SHOW_TOAST_MESSAGE",
  DELETE_LOOKUP: "DELETE_LOOKUP",
  PROGRESS: "PROGRESS",
  DELETE_LOCAL_STORAGE_KEY: "DELETE_LOCAL_STORAGE_KEY",
  KILL_USER_SESSION: "KILL_USER_SESSION",
  PLATFORM_THEME_REFRESH: "PLATFORM_THEME_REFRESH",
  PLATFORM_GRID_REFRESH: "PLATFORM_GRID_REFRESH",
  PLATFORM_RAIL_REFRESH: "PLATFORM_RAIL_REFRESH",
}

const PLAYER = {
  ENTER: 13,
  RETURN: 10009,
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  RW: 412,
  PAUSE: 19,
  FF: 417,
  REC: 416,
  PLAY: 415,
  STOP: 413,
  SEEK_INTERVAL: 10,
  SEEK_10: 3,
  SEEK_30: 5,
  SEEK_60: 8,
  SEEK_90: 10,
  SEEK_120: 12,
  SEEK_180: 15,
  SEEK_others: 18,
  BACK_LG: 461,
  // https://developer.foxxum.com/docs/5_knowledgebase/
  VK_BACK_SPACE: 8,
  VK_ENTER: 13,
  VK_PAUSE: 19,
  VK_LEFT: 37,
  VK_UP: 38,
  VK_RIGHT: 39,
  VK_DOWN: 40,
  VK_0: 48,
  VK_1: 49,
  VK_2: 50,
  VK_3: 51,
  VK_4: 52,
  VK_5: 53,
  VK_6: 54,
  VK_7: 55,
  VK_8: 56,
  VK_9: 57,
  VK_PLAY_PAUSE: 40,
  VK_RED: 403,
  VK_GREEN: 404,
  VK_YELLOW: 405,
  VK_BLUE: 406,
  VK_REWIND: 412,
  VK_STOP: 413,
  VK_PLAY: 415,
  VK_FAST_FWD: 417,
  VK_PREV: 424,
  VK_NEXT: 425,
  VK_BACK: 461,
  // emulator
  EM_BACK: 8,
  EM_PLAY: 179,
  EM_PAUSE: 234,
  EM_FAST_FWD: 228,
  EM_REWIND: 227,
  EM_PREV: 177,
  EM_NEXT: 176,

}

const NAVDIRECTION = {

  LEFT: 0,
  RIGHT: 1,

}

const OTTROUTETYPE = {
  Player: 1,
  Page: 2,
  NoRoute: 3,
  PI_Page: 4,
}

const LOGOPOSITION = {
  Left: 1,
  Center: 2,
  Right: 3,
}

const OTT_POSTER_TYPE = {
  Free: 0,
  Generic: 1,
  Portrait: 2,
  Landscape: 3,
  Logo: 4,
  HeroBanner: 5,
  Circle: 6,
  Thumbnail: 7,
  New: 8,
  Poster: 9,
  Square: 10
}

const PLATFORM = [

  { SID: 0, Description: "All" },
  { SID: 1, Description: "Mobile" },
  { SID: 2, Description: "Android TV" },
  { SID: 3, Description: "Web" }

];

const LOCAL_STORAGE_KEY = {
  user: "user",
  pages: "pages",
}

const VODTYPE = {
  TVOD: 1,
  SVOD: 2,
  AVOD: 3,
  FREE: 4,
}

const SOCKET_BASEURL = "https://socket.bossstudio.tv/";

export { RAILTYPE, PLAYER, NAVDIRECTION, OTTROUTETYPE, LOGOPOSITION, OTT_POSTER_TYPE, SOCKET_ACTION, MODULE, SOCKET_EVENTS, SOCKET_BASEURL, PLATFORM, LOCAL_STORAGE_KEY, VODTYPE }