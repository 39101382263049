import React, { useRef, useCallback, useEffect, useState } from 'react';
import ImageCard from "./ImageCard";
import { useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';
import { getAssetByID } from '../../../Api/api';

const RailComponent = (props) => {
  const { onFocus, rowIndex, railType, setOttAsset, isMobile } = props;
  const { ref, focusKey, getCurrentFocusKey } = useFocusable({
    trackChildren: true,
    focusable: isMobile ? false : true,
    onFocus: onFocus,
  });
  const MediaCarouselRef = useRef(null);
  const [rail, setRail] = useState(props.rail);
  useEffect(() => {
    setRail(props.rail);
  }, [props.rail])
  const onCardFocus = useCallback(({ x, asset, node, ...rest }) => {
    console.log(getCurrentFocusKey());
    setCurrentOttAsset(asset);
    if (!isMobile) {
      node.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }

  }, [MediaCarouselRef]);

  const setCurrentOttAsset = async (asset) => {

    const ottAssetRes = await getAssetByID(asset._id);
    if (ottAssetRes.success) {
      console.log(ottAssetRes);
      setOttAsset(ottAssetRes.data);
    } else {
      setOttAsset(asset);
    }

  }

  var fontWeight = props?.ottSetting?.themeData?.rail?.fontWeight;

  return (
    <FocusContext.Provider value={focusKey}>


      <div ref={ref} className="railContainer">

        <div className="m-2 textColor">
          <p style={{ fontWeight: fontWeight < 4 ? "lighter" : fontWeight < 6 ? "normal" : fontWeight < 8 ? "bold" : "bolder", color: props?.ottSetting?.themeData?.rail?.color ?? "white", fontFamily: props?.ottSetting?.themeData?.rail?.fontFamily ?? "Roboto", fontSize: props?.ottSetting?.themeData?.rail?.fontSize + "px", marginBottom: 0 }}>{rail.DisplayTitle}</p>
        </div>

        <div className="ContentRowScrollingWrapper" ref={MediaCarouselRef}>

          <div className='ContentRowScrollingContent'>
            {

              rail.OttAsset.map((asset, index) => {
                return (<FocusContext.Provider value={focusKey}>
                  {/* <div key={index} style={{ color: "black" }}>
                    .
                  </div> */}
                  <ImageCard
                    railType={railType}
                    key={"img-" + index}
                    index={index}
                    asset={asset}
                    onFocus={(props) => onCardFocus({ ...props, asset })}
                    CustomeFocusKey={rail?.SID + "-" + index + "-" + rowIndex}
                    ottSetting={props?.ottSetting}
                    theme={props?.ottSetting?.themeData}
                    isMobile={isMobile}

                  />
                </FocusContext.Provider>)
              })}

          </div>
        </div>
      </div>
    </FocusContext.Provider>
  )
}

export default RailComponent;