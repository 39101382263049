import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useEffect, useRef, useState } from 'react';
import { LOCAL_STORAGE_KEY, PLAYER } from '../../constant';
import { sendOTP } from '../../Api/api';
import { useNavigate } from 'react-router-dom';
import { utility } from '../../utility/utility';

const LoginRightSide = ({ mobileNumber, setShowVerifyOTP, showVerifyOTP, setMobileNumber }) => {
    const otpRef = useRef();
    const userRef = useRef();
    const navigate = useNavigate();
    const { ref, focused } = useFocusable({
        onEnterRelease: async (e) => {
            if (!showVerifyOTP) {
                if (mobileNumber.length === 10) {
                    setMessage('');
                    let res = await sendOTP(mobileNumber);
                    if (res.success) {
                        userRef.current = res.data.user;
                        otpRef.current = res.data.otp.toString();
                        setMobileNumber('');
                        setShowVerifyOTP(true);
                    } else {
                        setMessage(res.message);
                    }

                } else {
                    setMessage('Please enter valid mobile number');
                }
            } else {
                if (mobileNumber.length === 4) {
                    setMessage('');
                    if (otpRef.current == mobileNumber) {
                        utility.setValue(LOCAL_STORAGE_KEY.user, userRef.current);
                        navigate('/');
                    } else {
                        setMessage('Please enter valid OTP');
                    }
                } else {
                    setMessage('Please enter all 4 digits');
                }
            }
        }
    });

    useEffect(() => {
        document.addEventListener('keydown', onBack);
        return () => {
            document.removeEventListener('keydown', onBack);
        }
    }, []);

    const [message, setMessage] = useState('');

    const onBack = (e) => {
        const { keyCode } = e;

        if (keyCode === PLAYER.VK_BACK_SPACE || keyCode === PLAYER.VK_BACK || keyCode === PLAYER.BACK_LG || keyCode === PLAYER.RETURN) {
            e.preventDefault();
            if (showVerifyOTP) {
                setMobileNumber('');
                setShowVerifyOTP(false);
            } else {
                navigate(-1);
            }
        }
    }

    return (
        <FocusContext.Provider >
            <div className='loginInputOuterDiv'>
                {showVerifyOTP ?
                    <ul className='otpOuterDiv'>
                        <li>{mobileNumber[0] ?? ""}</li>
                        <li>{mobileNumber[1] ?? ""}</li>
                        <li>{mobileNumber[2] ?? ""}</li>
                        <li>{mobileNumber[3] ?? ""}</li>
                    </ul>
                    : <input disabled={true} type="number" value={mobileNumber} className='loginInput' placeholder="Enter Mobile Number" />}
                <button className={`loginButton ${focused && 'active'}`} ref={ref}>{showVerifyOTP ? "Verify OTP" : "Login"}</button>
                {message.length && <div style={{
                    color: 'red',
                    marginTop: '10px'
                }}>{message}</div>}
            </div>
        </FocusContext.Provider>
    );
}

export default LoginRightSide;
