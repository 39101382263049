// https://www.npmjs.com/package/video.js?activeTab=readme
/**
 * This component will add Playback functionality for all media across the appusing Video.js
 *
 * @module views/components/Player
 * @memberof -Common
 */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { useNavigate } from 'react-router-dom'
import { PLAYER } from '../../../constant';
import './Player.scss';

/**
  * Represents a Player component
  *
  * @method
  * @param {object} props - React properties passed from composition
  * @returns Player
  */
export const Player = function (props) {
  const navigate = useNavigate()
  let { src, asset } = props;
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const [loading, setLoading] = useState(true);

  const videoJsOptions = {
    autoplay: true,
    muted: false,
    controls: true,
    fluid: true,
    loadingSpinner: false,
    inactivityTimeout: 4000,
    html5: {
      vhs: {
        overrideNative: true
      },
      nativeAudioTracks: false,
      nativeVideoTracks: false
    }
  };
  let count = 0;

  // REFERENCE
  // https://video-react.js.org/components/player/

  // LG REMOTE
  // https://webostv.developer.lge.com/develop/guides/magic-remote

  const onKeyPress = useCallback((e) => {

    const { keyCode } = e;

    console.log(keyCode);
    if (keyCode === 10009 || keyCode == PLAYER.VK_BACK || keyCode == PLAYER.EM_BACK || keyCode == PLAYER.VK_BACK_SPACE) {
      e.preventDefault();
      onBack();
    }

    console.log(playerRef);

    if (keyCode === PLAYER.PLAY || keyCode === PLAYER.VK_PLAY || keyCode === PLAYER.EM_PLAY) {
      playerRef.current.player().play();
    }

    if (keyCode === PLAYER.PAUSE || keyCode === PLAYER.VK_PAUSE || keyCode === PLAYER.EM_PAUSE) {
      playerRef.current.player().pause();
    }

    if (keyCode === PLAYER.ENTER || keyCode === PLAYER.VK_ENTER || keyCode === PLAYER.VK_PLAY_PAUSE) {
      if (playerRef.current.player().paused()) playerRef.current.player().play();
      else playerRef.current.player().pause();
    }

    if (keyCode === PLAYER.STOP || keyCode === PLAYER.VK_STOP) {
      playerRef.current.player().currentTime(0);
      playerRef.current.player().pause();
    }

    if (keyCode == PLAYER.LEFT || keyCode == PLAYER.VK_LEFT || keyCode == PLAYER.EM_REWIND || keyCode == PLAYER.VK_REWIND || keyCode == PLAYER.EM_PREV || keyCode == PLAYER.VK_PREV) {
      playerRef.current.player().currentTime(playerRef.current.player().currentTime() - 10);
    }
    if (keyCode == PLAYER.RIGHT || keyCode == PLAYER.VK_RIGHT || keyCode == PLAYER.EM_FAST_FWD || keyCode == PLAYER.VK_FAST_FWD || keyCode == PLAYER.EM_NEXT || keyCode == PLAYER.VK_NEXT) {
      playerRef.current.player().currentTime(playerRef.current.player().currentTime() + 10);
    }

    // to show player controls
    if (keyCode == PLAYER.UP || keyCode == PLAYER.DOWN || keyCode == PLAYER.LEFT || keyCode == PLAYER.RIGHT || keyCode == PLAYER.VK_UP || keyCode == PLAYER.VK_DOWN || keyCode == PLAYER.VK_LEFT || keyCode == PLAYER.VK_RIGHT || keyCode == PLAYER.VK_UP_HEX || keyCode == PLAYER.VK_DOWN_HEX || keyCode == PLAYER.VK_LEFT_HEX || keyCode == PLAYER.VK_RIGHT_HEX) {

      console.log("calling")
      console.log(playerRef.userActive_);
      playerRef.userActive_ = true;
      console.log(playerRef.userActive_);

      var element = document.getElementById("player");
      element.classList.remove("vjs-user-inactive");
      element.classList.add("vjs-user-active");


    }






  });

  const calculatePlayerSeekInfo = (player) => {
    count++;
    const duration = player?.duration?.();
    const durationInSec = duration && duration / 60;
    if (durationInSec === undefined) {
      return;
    }
    switch (true) {
      case (durationInSec >= 180):
        playPausePlayer(player, PLAYER.SEEK_others);
        break;
      case (durationInSec >= 120):
        playPausePlayer(player, PLAYER.SEEK_180);
        break;
      case (durationInSec >= 90):
        playPausePlayer(player, PLAYER.SEEK_120);
        break;
      case (durationInSec >= 60):
        playPausePlayer(player, PLAYER.SEEK_90);
        break;
      case (durationInSec >= 30):
        playPausePlayer(player, PLAYER.SEEK_60);
        break;
      case (durationInSec >= 10):
        playPausePlayer(player, PLAYER.SEEK_30);
        break;
      default:
        playPausePlayer(player, PLAYER.SEEK_10);
        break;
    }
  }

  const playPausePlayer = (player, seekInfo) => {
    if (count > seekInfo) {
      player.play();
      player.pause();
      count = 0;
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyPress);
    return () => window.removeEventListener('keydown', onKeyPress);
  }, []);

  useEffect(() => {
    const player = playerRef.current;
    // on player duration change console log
    if (player) {
      player.on('ended', () => {
        onBack();
      });
    }
    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);


  const onBack = () => {
    const player = playerRef.current;

    if (player) {
      // player.dispose();
      playerRef.current = null;
    }
    // props.setPlayer( false );

    navigate(-1)
  }

  const onReady = (player) => {
    console.log('onReady', player); //eslint-disable-line
    console.log(asset)
    let newElement = document.createElement('div');
    newElement.setAttribute('id', 'playerTitle');
    newElement.innerHTML = asset.Title;
    newElement.className = 'player-title';

    let backElement = document.createElement('div');
    backElement.setAttribute('id', 'backIcon');
    backElement.setAttribute("aria-hidden", true);
    backElement.innerHTML = '<i class="fa fa-arrow-left" aria-hidden="true"></i>';
    backElement.className = 'vjs-back-icon';

    let controlBar = document.getElementsByClassName('vjs-control-bar')[0];
    let insertBeforeNode = document.getElementsByClassName('vjs-play-control')[0];

    controlBar?.insertBefore(newElement, insertBeforeNode);
    controlBar?.insertBefore(backElement, insertBeforeNode);


    backElement.addEventListener("click", onBack)

  };

  const launchPlayer = () => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (videoElement) {
        const player = (playerRef.current = videojs(videoElement, videoJsOptions, () => {
          onReady && onReady(player);
        }))
      }
    }
  }

  useEffect(() => {
    videoJsOptions.sources = [{ src: src }];
    launchPlayer();
  }, []);

  return (
    <div
      className='Player'
      data-vjs-player
      style={{ 'width': '100%', 'height': '100%' }}
    >
      <video // eslint-disable-line
        id='player'
        ref={videoRef}
        className='video-js'
      />
    </div>
  )
}

/**
  * Property type definitions
  *
  * @type {object}
  * @property {string} src - video url
  * @property {string} deepLinkUrl - deeplink url shared by partner for fetching playable url
  * @property {bool} bProtected - states whether url is protected or not
  */
export const propTypes = {
  src: PropTypes.string,
  rajat: PropTypes.string
};

Player.propTypes = propTypes;

export default Player;