import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useEffect, useRef, useState } from 'react';
import EpgChannels from './epgChannels';
import { v4 as uuidv4 } from 'uuid';
import { getEpg } from '../../../Api/api';
import { yellow } from '@mui/material/colors';
import EpgProgramCard from './epgProgramCard';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import EpgTimeline from './epgTimeline';

const Epg = ({
    focusKeyParam
}) => {
    const { ref, focusKey, focused } = useFocusable({
    });
    const [epg, setEpg] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [redLineFromLeft, setRedLineFromLeft] = useState(0);


    useEffect(() => {
        loadEpg();
    }, []);

    useEffect(() => {
        let pixelPerMinute = blockWidth / slotDuration;
        let currMinutes = new Date().getMinutes();
        let currHours = new Date().getHours();
        let currTotalMinutes = (currHours * 60) + currMinutes;
        let positionFromLeft = currTotalMinutes * pixelPerMinute;
        setRedLineFromLeft(positionFromLeft);
        setTimeout(() => {
            document.getElementById("timeline")?.scrollTo({
                left: positionFromLeft < 200 ? positionFromLeft : positionFromLeft - 200,
                behavior: "smooth"
            });
            document.getElementById("wow")?.scrollTo({
                left: positionFromLeft < 200 ? positionFromLeft : positionFromLeft - 200,
                behavior: "smooth"
            });
        }, 1000)
    }, [isLoading])

    const loadEpg = async () => {
        setIsLoading(true);
        let res = await getEpg();
        if (res.success) {
            setEpg(res.data);
            // if (res.data.length > 0) {
            //     setSelectedEpg(res.data[0]);
            // }
        }
        setIsLoading(false);
    }

    const slotDuration = 30;
    let timelineTime = [];
    let totalMinutesInADay = 24 * 60;
    let tempStatTime = 0;
    const blockWidth = 200;
    const blockHeight = 70;

    while (tempStatTime < totalMinutesInADay) {
        timelineTime.push(moment().startOf('day').add(tempStatTime, 'minute').format('HH:mm'))
        tempStatTime += slotDuration;
    }

    return (
        isLoading ?
            <div style={{ color: "white", display: "flex", justifyContent: "center", alignItems: "center", height: "calc(100vh - 60px)", width: "1200px", fontSize: "30px" }}>
                <i className='fa fa-spinner fa-pulse fa-lg' />
            </div>
            : <FocusContext.Provider value={focusKey}>

                <div ref={ref} style={{ paddingLeft: "20px", position: "relative" }} onScroll={(e) => { e.preventDefault() }}>
                    <div style={{ color: "white", height: "50px", fontFamily: "monospace", position: "relative" }}><h1 style={{}}>Channels</h1></div>
                    <h2 style={{ position: "fixed", right: 0, top: 0, color: "white", paddingRight: "20px", paddingLeft: "20px", fontFamily: "monospace", zIndex: 10000, backdropFilter: "blur(20px)", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }} id='channelName'></h2>
                    {/* timeline */}
                    <EpgTimeline slotDuration={slotDuration} blockWidth={blockWidth} />
                    <div style={{ position: "relative" }}>
                        {epg.map((e, i) => {
                            return <FocusContext.Provider value={focusKey}><EpgChannels
                                key={uuidv4()}
                                epgChannel={e}
                                CustomeFocusKey={"CHANNEL-" + i}
                                i={i}
                                blockHeight={blockHeight}
                                focusKeyParam={focusKey}
                            /> </FocusContext.Provider>
                        })}
                        {/* epg */}
                        <div style={{ height: "100%", width: "1300%", backgroundColor: "rebeccapurple", position: "relative", left: "150px", top: 0 }}>

                            <div onScroll={(e) => {
                                let timelineDiv = document.getElementById("timeline");
                                timelineDiv.scrollLeft = e.target.scrollLeft;
                            }} style={{ overflow: "scroll", display: "flex", position: "absolute", left: 0, width: `100%`, top: 0, }}>
                                {/* events */}
                                {/* epg program display area */}
                                <div id='wow' style={{ overflow: "scroll" }} onScroll={(e) => {
                                    let timelineDiv = document.getElementById("timeline");
                                    timelineDiv.scrollLeft = e.target.scrollLeft;
                                }}>
                                    {/* program cards */}
                                    <div id='epgTimelineArea' key={uuidv4()} style={{ height: epg.length * blockHeight, width: timelineTime.length * blockWidth, backgroundColor: "black", overflow: "scroll", position: "relative" }}>
                                        <div style={{ overflow: "hidden", position: "absolute", top: 0, width: "2px", height: "100%", backgroundColor: "red", zIndex: 10000, left: 5 + redLineFromLeft }}>
                                        </div>
                                        {
                                            epg.map((e, i) => {
                                                let pixelPerMinute = blockWidth / slotDuration;

                                                return e.programList.map((p, j) => {
                                                    return <FocusContext.Provider value={focusKey}> <EpgProgramCard focusKeyParam={focusKey} key={uuidv4()} program={p} i={i} j={j} blockHeight={blockHeight} pixelPerMinute={pixelPerMinute} CustomeFocusKey={"program-" + i + '-' + j} /></FocusContext.Provider>
                                                })
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </FocusContext.Provider>
    );
}

export default Epg;
