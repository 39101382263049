import React, { useState } from 'react';
import Menu from './homePage/menu/menu';
import MainPage from './MainPage';
import Test from './homePage/epg/epg';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import Epg from './homePage/epg/epg';

const MainWrapper = ({ focusKeyParam,
    page, ottSetting, menu, isMobile }) => {

    const { ref } = useFocusable();

    const [currentPage, setCurrentPage] = useState(page);

    return (
        <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
        }}>
            {/* {!isMobile && <Menu focusKey={"MENU"} ottSetting={ottSetting} menu={menu} setCurrentPage={setCurrentPage} currentPage={currentPage} />} */}


            <Epg ref={ref} focusKeyParam={focusKeyParam}
                page={currentPage} ottSetting={ottSetting} isMobile={isMobile} /> : <MainPage ref={ref} setCurrentPage={setCurrentPage} focusKeyParam={focusKeyParam}
                    page={currentPage} ottSetting={ottSetting} isMobile={isMobile} />

        </div>
    );
}

export default MainWrapper;
