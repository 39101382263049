import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React from 'react';

const FullKeypadNum = ({ key, numValue, index, setSearchKey }) => {
    const { ref, focused } = useFocusable({
        focusKey: index,
        onEnterRelease: () => {
            if (typeof (numValue) == 'object') {
                if (numValue.props.children == 'backspace') {
                    setSearchKey(searchKey => searchKey.slice(0, -1));
                } else {
                    setSearchKey(searchKey => searchKey + ' ');
                }
            }
            else {
                setSearchKey(searchKey => searchKey + numValue);
            }
        }

    });
    return (
        <li
            ref={ref}
            key={key}
            className={`letter ${index % 6 === 0 ? 'clearl' : ''} ${focused ? 'active' : ''} ${typeof (numValue) == 'object' ? 'expand' : ''}`}
        > {numValue}
        </li>
    );
}

export default FullKeypadNum;
