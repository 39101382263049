import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useEffect } from 'react';
import KeypadNum from './keypadNum';

const Keypad = ({ setMobileNumber, showVerifyOTP }) => {
    const { ref, focusKey, focusSelf, setFocus } = useFocusable({
        focusable: true,
        saveLastFocusedChild: true,
        trackChildren: true,
        autoRestoreFocus: true,
        isFocusBoundary: false,
        focusKey: "login",
        preferredChildFocusKey: null,
        onArrowPress: () => true,
    });

    useEffect(() => {
        focusSelf();

    }, [focusSelf]);

    useEffect(() => {
        setFocus("login")

    }, [setFocus]);

    const keypadButtons = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '←', '0', 'x'];


    return (
        <FocusContext.Provider value={focusKey}>
            <div className='keypadOuterDiv'>
                <ul id="keypad" ref={ref}>
                    {keypadButtons.map((button, index) => (
                        <KeypadNum key={button} numValue={button} index={index} setMobileNumber={setMobileNumber} showVerifyOTP={showVerifyOTP} />
                    ))}
                </ul>
            </div>
        </FocusContext.Provider>
    );
}

export default Keypad;
