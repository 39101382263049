import React from 'react';
import { utility } from '../../../utility/utility';
import { OTT_POSTER_TYPE } from '../../../constant';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useNavigate } from 'react-router-dom';

const EpgChannels = ({
    epgChannel, i, blockHeight, CustomeFocusKey
}) => {

    const onFocus = (props) => {
        props.node.scrollIntoView({ behavior: "smooth", block: "end" });
        document.getElementById("channelName").innerText = epgChannel?.channelDisplayName ?? "";
    }

    const navigate = useNavigate();

    const { ref, focused, focusKey } = useFocusable({
        focusKey: CustomeFocusKey,
        onFocus: onFocus,
        onEnterRelease: () => {
            navigate('/liveTv', { state: { asset: { ...epgChannel.ottAsset, VideoPlaybackUrl: epgChannel.ottAsset.Videos[0].Url } } });
        }

    });

    return <div ref={ref}
        id={"channel" + i}
        onClick={() => {
            navigate('/liveTv', { state: { asset: { ...epgChannel.ottAsset, VideoPlaybackUrl: epgChannel.ottAsset.Videos[0].Url } } });
        }}
        style={{
            width: "150px",
            height: blockHeight - 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            backgroundColor: "#1f1a30",
            opacity: focused ? 1 : 0.5,
            zIndex: 2,
            position: "absolute",
            flexDirection: "column",
            top: i * blockHeight + 3,
            cursor: "pointer",
            border: focused ? "2px solid white" : "none",
            borderRadius: "5px",
            transition: "opacity 0.23s ease-in-out",
        }}>
        <img src={utility.getPosterUrl(epgChannel.ottAsset.Posters, OTT_POSTER_TYPE.Portrait)} style={{
            maxWidth: "70px", maxHeight: blockHeight - 10, overflow: "hidden",
        }} />
    </div>




}

export default EpgChannels;
