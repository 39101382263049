import { OTT_POSTER_TYPE, RAILTYPE } from "../constant";

export const utility = {};

utility.getValue = function (key) {
    return JSON.parse(localStorage.getItem(key));
};


utility.setValue = function (key, value) {

    if (value == undefined) {
        value = null;
    }

    localStorage.setItem(key, JSON.stringify(value));
};

utility.deleteValue = function (key) {
    localStorage.removeItem(key);
};

utility.getPosterUrl = (posters, posterType) => {

    if (!posters || typeof posters == 'string' || posters.length == 0) {
        return ""
    }

    var poster = posters.find((x) => x.OttPosterType.SID == posterType);

    return poster ? poster.Url : posters[0].Url

}

utility.getValidPosterFromRail = (railType) => {
    switch (railType) {
        case RAILTYPE.Rail:
            return OTT_POSTER_TYPE.Portrait;
        case RAILTYPE.CircleRail:
            return OTT_POSTER_TYPE.Circle;
        case RAILTYPE.PotraitRail:
            return OTT_POSTER_TYPE.Portrait;
        case RAILTYPE.LandscapeRail:
            return OTT_POSTER_TYPE.Landscape;
        case RAILTYPE.SquareRail:
            return OTT_POSTER_TYPE.Square;
        default:
            return OTT_POSTER_TYPE.Free;
    }
}

utility.getMediaCategoryType = (value) => {
    switch (value) {
        case 0:
            return "";
        case 1:
            return "Program";
        case 2:
            return "Episode";
        case 3:
            return "Movie";
        case 4:
            return "Interstitial";
        case 5:
            return "Commercial";
        case 6:
            return "Filler";
        case 7:
            return "Music";
        case 8:
            return "Series";
        case 9:
            return "Season";
        case 10:
            return "Trailer";
        default:
            return "";
    }
}

utility.formatMilliseconds = (milliseconds) => {
    var hours = Math.floor(milliseconds / 1000 / 60 / 60);
    var minutes = Math.floor((milliseconds / 1000 / 60 / 60 - hours) * 60);
    return hours + "h " + minutes + "m ";
}