import { useNavigate } from "react-router-dom";
import './homePage.css'
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useCallback } from "react";
import { LOCAL_STORAGE_KEY, OTTROUTETYPE, OTT_POSTER_TYPE, RAILTYPE, VODTYPE } from "../../../constant";
import { utility } from "../../../utility/utility"
import crown from '../../../images/crown.png';

const ImageCard = (props) => {
  const { onFocus, asset, CustomeFocusKey, railType, ottSetting, theme, index, isMobile } = props;
  const { ref, focused, setFocus, focusKey } = useFocusable({
    focusKey: CustomeFocusKey, onFocus: onFocus, onEnterPress: () => onClick(), focusable: isMobile ? false : true
  });

  const isFree = asset.VodTypes?.SID ?? VODTYPE.FREE == VODTYPE.FREE;

  const AssetWrapper = {

    "display": "flex",
    "flexdirection": "column",
    "height": "180px",
    "marginTop": "10px",

  }

  const onMouseEnter = useCallback((e) => {
    console.log(CustomeFocusKey)
    console.log(focusKey)
    setFocus(focusKey);

  }, [ref]);

  const navigate = useNavigate();

  const onClick = () => {
    const canPlay = isFree || utility.getValue(LOCAL_STORAGE_KEY.user)?.PaymentPlan?.length > 0 ? true : false ?? false;
    switch (asset.routeType) {
      case OTTROUTETYPE.Page:
        var pages = utility.getValue(LOCAL_STORAGE_KEY.pages);
        var page_id = asset.page_id;
        let page = pages.find(p => p._id === page_id);
        console.log(page);
        if (page) {
          navigate(isMobile ? '/mobile' : '/', { state: { page: page } })
        }
        break;
      case OTTROUTETYPE.Player:
        if (canPlay) {
          navigate('/liveTv', { state: { asset: asset } })
        } else {
          navigate('/login');
        }
        break;
      case OTTROUTETYPE.PI_Page:
        navigate('/' + asset.Title.toString().replace(' ', '_'), { state: { asset: asset, ottSetting: ottSetting, isMobile: isMobile } })
        break;
      default:
        if (canPlay) {
          navigate('/liveTv', { state: { asset: asset } })
        } else {
          navigate('/login');
        }
        break;
    }
  }

  const style = {
    border: `4px solid ${focused ? theme?.rail?.focusColor : "transparent" ?? "black"}`,
    transition: "all 0.4s"
  }

  function getImage() {
    switch (parseInt(railType)) {
      case RAILTYPE.Rail:
      case RAILTYPE.LandscapeRail:
        return <div ref={ref} style={{ ...AssetWrapper, width: "300px", height: ottSetting?.showProviderIcon ? "220px" : "180px", position: "relative", marginTop: 0 }} onMouseEnter={onMouseEnter}>
          {!isFree && <img className={focused ? "crown focus" : "crown"} src={crown} />}
          <img style={{ ...style, width: "300px", height: "180px" }} onClick={onClick} className={focused ? 'card-img img-focused' : 'card-img'} src={utility.getPosterUrl(asset.Posters, OTT_POSTER_TYPE.Landscape)} alt="Card image cap" />
          {/* provider image */}
          {(ottSetting?.showProviderIcon && asset?.Provider?.LogoUrl?.length > 0) && <img src={asset?.Provider?.LogoUrl ?? ""} style={{
            position: "absolute",
            height: "35px",
            width: "35px",
            borderRadius: "5px",
            bottom: focused ? "0px" : "10px",
            left: focused ? "10px" : "20px",
            transition: "all 0.4s"
          }} />}
        </div>;
      case RAILTYPE.PotraitRail:
      case RAILTYPE.VerticalRail:
        return <div ref={ref} style={{ ...AssetWrapper, width: isMobile ? "130px" : "170px", height: isMobile ?  ottSetting?.showProviderIcon ?"180px":"220px" :  ottSetting?.showProviderIcon ?"290px":"250px", position: "relative", marginTop: 0 }} onMouseEnter={onMouseEnter}>
          {!isFree && <img className={focused ? "crown focus" : "crown"} src={crown} />}
          <img style={{ ...style, width: isMobile ? "130px" : "170px", height: isMobile ? "180px" : "250px" }} onClick={onClick} className={focused ? 'card-img img-focused' : 'card-img'} src={utility.getPosterUrl(asset.Posters, OTT_POSTER_TYPE.Portrait)} alt="Card image cap" />
             {/* provider image */}
             {(ottSetting?.showProviderIcon && asset?.Provider?.LogoUrl?.length > 0) && <img src={asset?.Provider?.LogoUrl ?? ""} style={{
            position: "absolute",
            height: "35px",
            width: "35px",
            borderRadius: "5px",
            bottom: focused ? "0px" : "10px",
            left: focused ? "10px" : "20px",
            transition: "all 0.4s"
          }} />}
        </div>;
      case RAILTYPE.CircleRail:
        return <div ref={ref} style={{ ...AssetWrapper, width: "120px", height: ottSetting?.showProviderIcon ?"160px": "120px", borderRadius: "100%", marginRight: "20px", marginBottom: "30px", position: "relative", marginTop: 0 }} onMouseEnter={onMouseEnter}>
          {!isFree && <img className={focused ? "crown focus" : "crown"} src={crown} />}
          <img style={{ ...style, width: "120px", height: "120px", borderRadius: "100%", }} onClick={onClick} className={focused ? 'card-img img-focused' : 'card-img'} src={utility.getPosterUrl(asset.Posters, OTT_POSTER_TYPE.Circle)} alt="Card image cap" />
             {/* provider image */}
             {(ottSetting?.showProviderIcon && asset?.Provider?.LogoUrl?.length > 0) && <img src={asset?.Provider?.LogoUrl ?? ""} style={{
            position: "absolute",
            height: "35px",
            width: "35px",
            borderRadius: "5px",
            bottom: focused ? "0px" : "10px",
            left: focused ? "10px" : "20px",
            transition: "all 0.4s"
          }} />}
        </div>

      case RAILTYPE.TopCounter:
        return <div ref={ref} style={{ ...AssetWrapper, width: isMobile ? "130px" : "170px", height: isMobile ?ottSetting?.showProviderIcon ?"220px": "180px" : ottSetting?.showProviderIcon ?"290px":"250px", position: "relative", marginTop: 0 }} onMouseEnter={onMouseEnter}>
          {!isFree && <img className={focused ? "crown focus" : "crown"} src={crown} />}
          <img style={{ ...style, width: isMobile ? "130px" : "170px", height: isMobile ? "180px" : "250px", }} onClick={onClick} className={focused ? 'card-img img-focused' : 'card-img'} src={utility.getPosterUrl(asset.Posters, OTT_POSTER_TYPE.Portrait)} alt="Card image cap" />
          <div className="topCounter" style={{ position: "absolute", right: 0, bottom: focused ? -20 : -10, fontSize: isMobile ? "4rem" : "5rem", color: "white", transition: "all 0.4s" }}>
            {index + 1}
          </div>
             {/* provider image */}
             {(ottSetting?.showProviderIcon && asset?.Provider?.LogoUrl?.length > 0) && <img src={asset?.Provider?.LogoUrl ?? ""} style={{
            position: "absolute",
            height: "35px",
            width: "35px",
            borderRadius: "5px",
            bottom: focused ? "0px" : "10px",
            left: focused ? "10px" : "20px",
            transition: "all 0.4s"
          }} />}
        </div>
      case RAILTYPE.SquareRail:
        return <div ref={ref} style={{ ...AssetWrapper, width: "180px", height: ottSetting?.showProviderIcon ?"220px": "180px", position: "relative", marginTop: 0 }} onMouseEnter={onMouseEnter}>
          {!isFree && <img className={focused ? "crown focus" : "crown"} src={crown} />}
          <img style={{ ...style, width: "180px", height: "180px" }} onClick={onClick} className={focused ? 'card-img img-focused' : 'card-img'} src={utility.getPosterUrl(asset.Posters, OTT_POSTER_TYPE.Square)} alt="Card image cap" />
             {/* provider image */}
             {(ottSetting?.showProviderIcon && asset?.Provider?.LogoUrl?.length > 0) && <img src={asset?.Provider?.LogoUrl ?? ""} style={{
            position: "absolute",
            height: "35px",
            width: "35px",
            borderRadius: "5px",
            bottom: focused ? "0px" : "10px",
            left: focused ? "10px" : "20px",
            transition: "all 0.4s"
          }} />}
        </div>

      default:
        return <div ref={ref} className={'col-3'} style={AssetWrapper} onMouseEnter={onMouseEnter}>
          {!isFree && <img className={focused ? "crown focus" : "crown"} src={crown} />}
          <img style={style} onClick={onClick} className={focused ? 'card-img img-focused' : 'card-img'} src={utility.getPosterUrl(asset.Posters, OTT_POSTER_TYPE.Landscape)} alt="Card image cap" />
        </div>
    }
  }

  return getImage();

}

export default ImageCard;