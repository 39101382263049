import React, { useEffect, useState } from 'react';
import Keypad from './keypad';
import './login.css';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import LoginRightSide from './loginRightSide';

const Login = () => {
    const { ref, focusKey } = useFocusable();

    const [mobileNumber, setMobileNumber] = useState('');
    const [showVerifyOTP, setShowVerifyOTP] = useState(false);

    return (
        <FocusContext.Provider value={focusKey}>
            <div id="login" ref={ref} >
                <div className='loginLogo'>
                    <img src="https://res.cloudinary.com/dl2n34gbw/image/upload/v1691669185/qemx571fq9mqvwgagscd.png" alt="logo" />
                </div>
                <div id='loginContent'>
                    {/* Pass the focusKeyParam prop to Keypad */}
                    <Keypad setMobileNumber={setMobileNumber} showVerifyOTP={showVerifyOTP} />
                    {/* Enter mobile number */}
                    <LoginRightSide mobileNumber={mobileNumber} showVerifyOTP={showVerifyOTP} setShowVerifyOTP={setShowVerifyOTP} setMobileNumber={setMobileNumber} />
                </div>
            </div>
        </FocusContext.Provider>
    );
}

export default Login;
