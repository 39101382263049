import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";

function MyButton(props) {
  const { ref, focused } = useFocusable({
    onEnterPress: () => {
      props.openExitDialogue();
    },
    focusKey: !props.focusKeyRefrence ? null : `${props.focusKeyRefrence}`,
  });

  return (
    <div ref={ref}>
      <button
        onClick={props.openExitDialogue}
        className={focused ? "button-81-focused" : "button-81"}
        role="button"
      >
        {props.title}
      </button>
    </div>
  );
}

export default MyButton;
