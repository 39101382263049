import './App.css';
import { Route, Routes } from 'react-router-dom'
import LiveTv from './tv/LiveTv/LiveTv';
import { init } from '@noriginmedia/norigin-spatial-navigation';
import Preview from './tv/homePage/components/Preview';
import PiPage from './tv/homePage/components/PiPage';
import Login from './tv/login/login';
import SearchPage from './tv/homePage/search/searchPage';
import Epg from './tv/homePage/epg/epg';



function App() {

  init({
    throttleKeypresses: true,
    throttle: 100
  });

  return <Routes>
    <Route path='/' element={<Preview />} />
    <Route path='/mobile' element={<Preview />} />
    <Route path='/login' element={<Login />} />
    <Route path='/search' element={<SearchPage />} />
    <Route path='/liveTv' element={<LiveTv />} />
    <Route path='/epg' element={<Epg />} />
    <Route path='/:title' element={<PiPage />} />
  </Routes>
}

export default App;


